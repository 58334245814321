import React from 'react'
import Navbar from '../components/Navbar'
import Socials from '../components/Socials'
function About() {
  return (
    <div className="home-page">
      <img className="logo" src="images\logospace.png"></img>
      <Navbar></Navbar>
      <article className='about-article'>
        <img className="about-inline-art" src="images\about-inline-art.png"></img>
        <p className="about-text"> Dedicated visual artist studying at Laguna College of Art + Design in pursuit of a career designing meaningful experiences in entertainment.

My brand is an extension of my love for childhood toys. As a little girl (and an only child), I constantly played pretend with my toys so I could chase the thrill of adventure, yet still feel comfortable and safe. 

Presently, I enjoy playing this same way through my artwork, and I hope to provide the same experience to my audience. Even my work that does not feature toys, or is not ‘about’ toys, is still therefore about playing; pattern-seeking, problem-solving, — teaching oneself how to learn and how to change.</p>
      </article>
        <img className='about-gallery-img' src='images/digital-art/character1.png'></img>
    </div>
  )
}

export default About