import React, { useEffect, useState } from 'react'
import ImageList from "@mui/material/ImageList";
import ArtPiece from '../components/ArtPiece';
import Navbar from '../components/Navbar';
import { useParams } from 'react-router-dom';
let uncategorizedImages = [
  {
    source: "uncategorized/art1",
    title: "Title",
    description: "Description",
  },
  {
    source: "uncategorized/art2",
    title: "Title",
    description: "Description",
    width:1,
    height:1,
  },
  {
    source: "uncategorized/art3",
    title: "Title",
    description: "Description",
    width:1,
    height:1,
  },
  {
    source: "uncategorized/art4",
    title: "Title",
    description: "Description",

  },
  {
    source: "uncategorized/art5",
    title: "Title",
    description: "Description",
  },
  {
    source: "uncategorized/art6",
    title: "Title",
    description: "Description",
  },
  {
    source: "uncategorized/art7",
    title: "Title",
    description: "Description",
  },
  {
    source: "uncategorized/art8",
    title: "Title",
    description: "Description",
  },
]; 
let plushDollImages = [
  {
    source: "plush-dolls/plush1a",
    title: "1",
    description: "Description",
  },
  {
    source: "plush-dolls/plush2",
    title: "2",
    description: "Description",
  },
]; 
 let digitalArtImages = [
  {
    source: "digital-art/character1",
    title: "1",
    description: "Description",
  },
  {
    source: "digital-art/character2",
    title: "2",
    description: "Description",
  },
  {
    source: "digital-art/character3",
    title: "3",
    description: "Description",
  },
  {
    source: "digital-art/painting1",
    title: "4",
    description: "Description",
  },
  {
    source: "digital-art/painting2",
    title: "5",
    description: "Description",
  },
  {
    source: "digital-art/painting3",
    title: "6",
    description: "Description",
  },
  {
    source: "digital-art/painting4",
    title: "7",
    description: "Description",
  },
];


let galleries = {
  "all": uncategorizedImages.concat(plushDollImages, digitalArtImages),
  "plush-dolls": plushDollImages,
  "digital-art": digitalArtImages,
}
const Gallery = ({images}) => {
  let {gallery} = useParams();
  let [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(()=>{
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  },[])
  return (
    
    <div className='gallery'>
      <Navbar className="Navbar__menu">
      
      </Navbar>
      <ImageList sx={{ width: "100%", padding:"16px", boxSizing:"border-box", margin:"0px"}} cols={Math.ceil(3*windowWidth/2000)*2} rowHeight={.8*windowWidth/(2*Math.ceil(3*windowWidth/2000))} variant="quilted">
        {galleries[gallery].map((image, index)=>{
          return <ArtPiece key={index} id={image.source} title={image.title} description={image.description} rows={image.height} cols={Math.min(Math.ceil(3*windowWidth/2000)*2,image.width?image.width:2)}></ArtPiece>
        })}
      </ImageList>
    </div>
  
  )
}

export default Gallery