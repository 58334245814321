import React from 'react'
import Navbar from '../components/Navbar'
const Home = () => {
  return (
    <div className="home-page">
      <img className="logo" src="images\logospace.png"></img>
      <Navbar></Navbar>
      <img className="home-art" src="images\home-art.png"></img>
      {
      //<p className="artist-statement-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus hendrerit tortor sit amet efficitur. Curabitur dignissim viverra neque, ac condimentum ex mollis at. Suspendisse varius odio lorem, ac interdum purus convallis et. Duis vel aliquam sem. Proin tempus ipsum ac lorem tincidunt volutpat. Sed turpis orci, molestie nec magna sit amet, gravida tincidunt nunc.</p>
      }
    </div>
    
  )
}

export default Home