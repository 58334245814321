import MenuItem from './MenuItem';

const Navbar = () => {
    
    return (
        <ul className="Navbar__menu">
            <li>
                <MenuItem title="Home" link={process.env.PUBLIC_URL + "/home"}>

                </MenuItem>
            </li>
            <li>
                <MenuItem title="Galleries" link={process.env.PUBLIC_URL + "/gallery/all"}>
                    <MenuItem title="Digital Art" link={process.env.PUBLIC_URL + "/gallery/digital-art"}></MenuItem>
                    <MenuItem title="Plush Dolls" link={process.env.PUBLIC_URL + "/gallery/plush-dolls"}></MenuItem>
                </MenuItem>
            </li>
            <li>
                <MenuItem title="About" link={process.env.PUBLIC_URL + "/about"}>
                </MenuItem>
            </li>
            <li>
                <MenuItem title="Contact" link={process.env.PUBLIC_URL + "/contact"}>
                </MenuItem>
            </li>
        </ul>
    );
};

export default Navbar;