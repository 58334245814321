import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <div>© Veronica Peterson 2023</div>
        <div>
            <a href='https://www.instagram.com/vesuviscera/' target="_blank"><img src='/images/insta-logo.png'></img></a>
            <a href='https://www.artstation.com/vesuviscera' target="_blank"><img src='/images/artstation-logo.png'></img></a>
            <a href='https://www.etsy.com/shop/VesuvisceraArt' target="_blank"><img src='/images/etsy-logo.png'></img></a>
        </div>
    </div>
  )
}

export default Footer